@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import "../colors";

$font-family-base: 'Open Sans', sans-serif;
$navbar-brand-height: 5rem;
$border-radius: 0;
$btn-border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$link-color: $cyan;

@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';